import React from 'react';
import './Hero.css';

const Hero: React.FC = () => {
	return (
		<section className="hero">
			<img src="hero-min.jpg" alt="" />
			<div className="overlay">
				<div className="w-container">
					<div className="hero-body">
						<h1>Nettoyage, conciergerie et travaux extérieurs</h1>
						<p>
							Bienvenue chez AECO Pro Sàrl : multiservice sur mesure pour votre
							tranquillité d'esprit. Confiez-nous vos besoins d'entretien ; nous
							garantissons un service de qualité impeccable.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
