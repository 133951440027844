import React from 'react';
import {Outlet} from "react-router-dom";
import "./Layout.css";
import Header from "./Header";
import Footer from "./Footer";

const Layout: React.FC = () => {
    return (
        <div>
            <Header />
            <Outlet/>
            <Footer />
        </div>
    )
}

export default Layout;