export interface IQuestion {
	question: string;
	answer: string;
}

export const nettoyageQuestions: IQuestion[] = [
	{
		question: 'Nettoyage régulier de la maison',
		answer: '',
	},
	{
		question: 'Nettoyage en profondeur',
		answer: '',
	},
	{
		question: "Nettoyage d'emménagement/déménagement",
		answer: '',
	},
	{
		question: 'Nettoyage de tapis',
		answer: '',
	},
	{
		question: 'Nettoyage de sols',
		answer: '',
	},
	{
		question: "Nettoyage d'appareils électroménagers",
		answer: '',
	},
	{
		question: 'Nettoyage de salles de bains',
		answer: '',
	},
	{
		question: 'Nettoyage de meubles',
		answer: '',
	},
];
export const travauxQuestions: IQuestion[] = [
	{
		question: 'Aménagements extérieurs',
		answer: '',
	},
	{
		question: 'Murs de gabion',
		answer: '',
	},
	{
		question: 'Talus en fleximur',
		answer: '',
	},
	{
		question: 'Maçonnerie & génie civil',
		answer: '',
	},
	{
		question: 'Entretiens des jardins',
		answer: '',
	},
	{
		question: "Plantation d'arbres",
		answer: '',
	},
	{
		question: 'Déneigement',
		answer: '',
	},
	{
		question: 'Pose de carrelage',
		answer: '',
	},
];
