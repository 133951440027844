import React from 'react';
import './nettoyage.css';
import { nettoyageQuestions } from '../utils/questions';
import QuestionAccordion from '../components/QuestionAccordion';
import ImageComparison from '../components/ImageComparison';
import 'video-react/dist/video-react.css';
import { Player } from 'video-react';

import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSprayCan } from '@fortawesome/free-solid-svg-icons';

const Nettoyage: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>Nettoyage | AÉCO Pro Sàrl</title>
				<meta
					name="description"
					content="Découvrez la quintessence de la propreté avec AECO Pro Sàrl. Forte de plus de 25 années d'expérience, nous apportons une expertise inégalée à chaque espace. Des maisons aux bureaux, nous transformons les environnements avec un héritage d'excellence. Faites confiance à notre parcours, choisissez AECO Pro pour un espace impeccable qui reflète des années de compétence et de dévouement perfectionnés."
				/>
			</Helmet>
			<section className="service-hero red-container m-container" id="hero">
				<FontAwesomeIcon icon={faSprayCan} size="4x" />
				<h2 className="title">Nettoyage</h2>
				<p className="description">
					25 ans d'expertise en propreté. AECO Pro Sàrl transforme chaque espace avec un
					savoir-faire inégalé. Des maisons aux bureaux, une tradition d'excellence. Optez
					pour la compétence et le dévouement, choisissez AECO Pro pour un espace
					impeccable.
				</p>
			</section>
			<section className="services w-container" id="services">
				<h2>Services offertes</h2>
				<p className="description">
					Proposant divers services de nettoyage résidentiel et professionel, nous
					assurons un entretien régulier, un nettoyage en profondeur, tout en adoptant une
					approche écologique avec l'utilisation de produits respectueux de
					l'environnement. Du nettoyage des sols aux appareils électroménagers, nous
					sommes là pour maintenir votre maison impeccable.
				</p>
				<a className="btn" href="#exemples">
					Voir les exemples de notre travail
				</a>
				<div className="questions">
					{nettoyageQuestions.map((question, index) => (
						<QuestionAccordion question={question} key={index} />
					))}
				</div>
			</section>

			<section id="exemples" className="exemples w-container m-container">
				<h2>Exemples de notre travail</h2>
				<p className="description">
					Pour apprécier la qualité de notre travail, nous vous invitons à observer les
					résultats concrets de nos services.
				</p>
				<div className="image-grid">
					<ImageComparison
						className="image"
						before="/comparisons/sink/before.png"
						after="/comparisons/sink/after.png"
					/>
					<Player width={300} height={300} fluid={false}>
						<source src="/comparisons/videos/sink.mp4" />
					</Player>
					<img
						src="/comparisons/steam.png"
						alt=""
						className="image"
						width="300px"
						height="300px"
					/>
					{/*
					// @ts-ignore */}
					<Player width={300} height={300} fluid={false}>
						<source src="/comparisons/videos/steam.mp4" />
					</Player>
				</div>
			</section>
		</>
	);
};

export default Nettoyage;
